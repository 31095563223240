<template>
  <div class="quick-actions">
    <h2 class="slim-title">Quick Actions</h2>
    <div class="flex-card-container">
      <Card title="Switch Current Site" secondary alt>
        <h4 class="card__subtitle">Display info from a specific site</h4>
        <div class="card__dropdowns">
          <SearchDropdown label="Sites" is-manager  all-label="All Sites" @input="handleSiteChoice" />
        </div>
      </Card>

      <Card :title="reportCards[0].title" secondary alt>
        <h4 class="card__text">All contractors with info linked to selected site <b>{{ currentSite.name }}</b></h4>
        <div class="card__Fdropdowns">
          <SearchDropdown label="Year" is-year :index="0" @input="handleYearInput" />
        </div>
        <button class="card__download" @click="downloadReport(0)">
          <i class="material-icons">save_alt</i>
        </button>
      </Card>
      <Card title="Add a Contractor" secondary alt>
        <p v-if="$device.desktop" class="card__text">To selected site <b>{{currentSite.name}}</b></p>
        <div class="card__dropdowns">
          <SearchDropdown label="Contractor" gqlModel="contractors" is-contractors @input="handleContractor" />
        </div>
        <button @click="addContractor" class="button button--plus" :disabled="!contractor">
          <i class="material-icons">add_circle</i>
        </button>
      </Card>

    </div>
    <br/>
    <div class="flex-card-container">
      <Card
          v-for="(text, title) in cards"
          :key="title"
          :title="title"
          :class="{ 'card--focused': focusedCard === title }"
      >
        <p v-if="$device.desktop" class="card__text">{{ text }}</p>
        <button v-if="focusedCard !== title" class="button button--plus" @click="focusedCard = title">
          <i class="material-icons">add_circle</i>
        </button>
        <button v-else class="button button--plus" @click="closeActionBlock">
          <i class="material-icons white">remove_circle</i>
        </button>
      </Card>
    </div>

    <ActionBlock
        v-if="focusedCard !== null"
        :card-text="cards[focusedCard]"
        is-manager
        :card="focusedCard"
        :form-site-id="siteId"
        @close="closeActionBlock"
    />
  </div>
</template>

<script>
import SearchDropdown from '@/components/_parts/SearchDropdown.vue'
import ActionBlock from './ActionBlock.vue'
import Card from '../_parts/Card.vue'
import axios from "axios";
import headers from "@/helpers/headers";

export default {
  name: "ManagerActions",
  components: {
    Card,
    ActionBlock,
    SearchDropdown
  },
  props: {
    siteId : {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      contractor: null,
      focusedCard: null,
      cards: {
        'ADD A COMPANY': 'Add a company so that contractors can identify themselves during sign up.',
      },
      reportCards: [
        {
          key: 'allContractorsBySite',
          title: 'Create a Report',
          subtitle: 'All contractors with info',
          fields: ['Year', 'Site'],
          year: null,
          siteId: null,
          apiRoute: '/reports/contractors-by-year-sites',
        },
      ],
    }
  },
  computed: {
    currentSite() {
      return this.$store.state.user.managingSites.find(({id}) => id === this.siteId)
    }
  },
  methods: {
    addContractor() {
      this.$store.dispatch('attachSite', {id: this.contractor, site_id: this.currentSite.id }).then(() => {
        this.contractor = null
        window.location.reload()
      })
    },
    handleContractor(id) {
      this.contractor = id
    },
    handleSiteChoice(id) {
      this.$emit('changeSite', id)
    },
    handleYearInput([year, index]) {
      this.reportCards[index].year = year
    },
    closeActionBlock() {
      this.focusedCard = null
    },
    downloadReport(index) {
      const baseUrl = `${process.env.VUE_APP_API_ENDPOINT}${this.reportCards[index].apiRoute}`
      const urlWithYear = `${baseUrl}?year=${this.reportCards[index].year}`
      const fullUrl = this.currentSite.id
          ? `${baseUrl}?year=${this.reportCards[index].year}&site_id=${this.currentSite.id}`
          : urlWithYear

      axios
          .get(fullUrl, {
            headers,
            responseType: 'blob',
          })
          .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.reportCards[index].key}-report.csv`)
            document.body.appendChild(link)
            link.click()
          })
    },
  }
}
</script>
