<template>
  <div v-if="$apollo.queries.contractor.loading">Loading info...</div>
  <div v-else>
    <div class="slide-sidemenu__content__buttons">
      <button
        v-if="isEdit"
        :disabled="!emailIsValid"
        class="slide-sidemenu__content__save-button button--regular"
        @click="handleSave()"
      >
        Save
      </button>
      <button v-if="isEdit" class="slide-sidemenu__content__save-button button--outlined" @click="handleCancel()">
        Cancel
      </button>
    </div>

    <div class="slide-sidemenu__content__profile-content--flex">
      <div class="slide-sidemenu__content__profile-content__field-container">
        <h5 class="slide-sidemenu__content__profile-content__field-title">Contractor Name</h5>
        <input
          v-if="isEdit"
          v-model="contractor.fullName"
          class="slide-sidemenu__content__profile-content__field-input"
          type="text"
        />
        <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ contractor.fullName }}</p>
      </div>

      <div class="slide-sidemenu__content__profile-content__field-container">
        <h5 class="slide-sidemenu__content__profile-content__field-title">Day of Birth</h5>
        <input
          v-if="isEdit"
          v-model="contractor.date_of_birth"
          class="slide-sidemenu__content__profile-content__field-input"
          type="date"
        />
        <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ contractor.date_of_birth }}</p>
      </div>
    </div>

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Company</h5>
      <p class="slide-sidemenu__content__profile-content__field-content">{{ contractor.companies[0].name }}</p>
      <button @click="$globalEvents.emit('open-menu', 'company', contractor.companies[0].id)">
        <i class="material-icons">remove_red_eye</i>
      </button>
    </div>

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Sites</h5>
      <div v-if="showAddSite" style="display: flex; align-items: center">
        <SearchDropdown label="Select Site"  all-label="All Sites" @input="handleSiteChoice" />
        <button class="button button--regular" style="height: 45px; position: initial" :disabled="!newSite" @click="submitSiteChoice">Confirm</button>
      </div>
      <div style="display: flex; flex-direction: column">
        <template  v-if="hasInductions">
          <template v-for="(site, index) in contractorSites" >
            <div v-if="site" style="display: flex; align-items: center; justify-content: space-between; margin-top: 1rem;" :key="index" >
              <div  class="slide-sidemenu__content__profile-content__field-content">{{ site.name }}</div>
              <div style="display: flex; align-items: center">
                <div  @click="detachSite(site)" class="slide-sidemenu__content__profile-content__field-button mr-10">
                  <i class="material-icons">delete</i>
                </div>
                <div  @click="$globalEvents.emit('open-menu', 'site', site.id)" class="slide-sidemenu__content__profile-content__field-button">
                  <i class="material-icons">remove_red_eye</i>
                </div>
              </div>

            </div>
          </template>
        </template>

        <p v-else class="slide-sidemenu__content__profile-content__field-content">
          This contractor has no assigned site yet.
        </p>
      </div>

      <button v-if="hasInductions" @click="toggleAddSite">
        <i class="material-icons">{{showAddSite ? 'close' : 'add'}}</i>
      </button>


    </div>

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Email</h5>
      <input
        v-if="isEdit"
        v-model="contractor.email"
        class="slide-sidemenu__content__profile-content__field-input"
        type="email"
      />
      <p v-else class="slide-sidemenu__content__profile-content__field-content">{{ contractor.email }}</p>
    </div>

    <hr />

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Current Status</h5>
      <p v-if="inductionsThisYear().length > 0" class="slide-sidemenu__content__profile-content__field-content">
        Induction Completed on {{ formatToYMD(inductionsThisYear()[0].completed_at) }}
      </p>
      <p v-else class="slide-sidemenu__content__profile-content__field-content">
        This contractor has not completed any inductions this year.
      </p>
      <p v-for="status in currentYear(contractor.inductions)" :key="status.id" class="slide-sidemenu__content__profile-content__field-content">
        {{ status.site.name }}:
        <span :class="completionStatusClass(status.on_site_training_done)">{{
            onSiteTrainingStatus(status.on_site_training_done)
          }}</span>
      </p>
    </div>

    <hr />

    <div class="slide-sidemenu__content__profile-content__field-container">
      <h5 class="slide-sidemenu__content__profile-content__field-title">Site Status History</h5>
      <div v-if="hasInductions">
        <p v-for="year in years" :key="year" class="slide-sidemenu__content__profile-content__field-content list-item">
          <span>{{ year }}</span>
          <span v-if="inductionsThisYear(year).length > 0">Induction Completed on {{ formatToYMD(inductionsThisYear(year)[0].completed_at) }}</span>
          <span v-else>This contractor has not done any inductions that year.</span>
        </p>
        <p
          v-for="status in sortHistory"
          :key="status.year"
          class="slide-sidemenu__content__profile-content__field-content list-item"
        >
          <span>{{ formatToYear(status.completed_at) }}</span>
          <span>
            {{ status.site.name }}
            On-Site Training :
            <br />
            <span :class="completionStatusClass(status.on_site_training_done)">{{
              onSiteTrainingStatus(status.on_site_training_done)
            }}</span>
          </span>
        </p>
      </div>
      <p v-else class="slide-sidemenu__content__profile-content__field-content">
        This contractor has not done any inductions yet.
      </p>
    </div>
  </div>
</template>

<script>
import contractorGQL from '@/gql/contractor'
import years from '@/helpers/years'
import headers from '@/helpers/headers'
import validationRegex from '@/helpers/validationRegex'
import SearchDropdown from "@/components/_parts/SearchDropdown.vue";

export default {
  name: 'ContractorSideMenu',
  components: {SearchDropdown},
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      contractor: {},
      showAddSite: false,
      newSite: false
    }
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear()
      const firstYear = years.startYear;

      return Array(currentYear - firstYear + 1)
          .fill(firstYear)
          .map((year, index) => year + index)
    },
    sortHistory() {
      const history = this.contractor.inductions;
      return history.sort((a, b) => new Date(b.completed_at) - new Date(a.completed_at));
    },
    contractorSites() {
      return this.contractor.inductions.map((induction) => {
        const inductionYear = Number(induction.completed_at.substr(0, 4))
        const currentYear = new Date().getFullYear()
        if(inductionYear === currentYear) {
          return induction.site
        } return null
      }, [])
    },
    hasInductions() {
      return this.contractor.inductions.length > 0
    },
    emailIsValid() {
      return validationRegex.email.test(this.contractor.email)
    },
  },
  methods: {
    inductionsThisYear(year = new Date().getFullYear()) {
      return this.contractor.inductions
          .filter(induction => new Date(induction.completed_at).getFullYear() === year);
    },
    currentYear(status) {
      return status.filter(el => years.getYear(new Date(el.completed_at)) === new Date().getFullYear());
    },
    submitSiteChoice() {
      this.$store.dispatch('attachSite', {id: this.contractor.id, site_id: this.newSite }).then(() => {
        this.toggleAddSite()
        this.$emit('reload')
      })
    },
    detachSite(site) {
      this.$store.dispatch('detachSite', {id: this.contractor.id, site_id: site.id }).then(() => {
        this.$emit('reload')
      })
    },
    handleSiteChoice(name, id) {
      this.newSite = id
    },
    toggleAddSite() {
      this.newSite = false
      this.showAddSite = !this.showAddSite
    },
    onSiteTrainingStatus(hasDoneIt) {
      return hasDoneIt ? 'Done' : 'To Do'
    },
    handleSave() {
      this.saveContractor()
    },
    handleCancel() {
      this.isEdit = false
      this.$emit('save')
    },
    completionStatusClass(isComplete) {
      return isComplete ? 'green' : 'red'
    },
    formatToYear(date) {
      return years.getYear(date)
    },
    formatToYMD(date) {
      return years.getYearMonthDay(date)
    },
    saveContractor() {
      const {
        contractor: { id, fullName, date_of_birth: dateOfBirth, email },
      } = this

      const [firstName, lastName] = fullName.split(' ')

      this.$apollo
        .mutate({
          mutation: contractorGQL.updateContractor,
          variables: {
            id,
            firstName,
            lastName,
            dateOfBirth,
            email,
          },
          context: {
            headers,
          },
        })
        .then(() => {
          this.$emit('save')
        })
        .catch(console.error)
    },
  },
  apollo: {
    contractor: {
      query: contractorGQL.getContractorProfile,
      variables() {
        return {
          id: this.id,
        }
      },
      context: {
        headers,
      },
      update: data => ({
        ...data.user,
        fullName: `${data.user.first_name} ${data.user.last_name}`,
      }),
    },
  },
}
</script>
