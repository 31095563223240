<template>
  <div class="orientation-section quiz">
    <template v-if="$device.desktop">
      <span class="dot"></span>
      <span class="side-stripe">
        <span class="line"></span>
      </span>
    </template>
    <h2 class="orientation-section__title">{{ $t("orientation.quiz.title") }}</h2>

    <template v-if="$store.state.isOrientationFinished && !$store.state.isQuizStarted">
      <div class="quiz__container">
        <div class="quiz__question">
          <h3 class="mb-25">{{ $t("orientation.quiz.gradingTitle") }}</h3>
          <div v-html="gradingContent"></div>
        </div>
      </div>
      <button class="button button--regular quiz__submit" @click="startQuiz">{{ $t("orientation.quiz.start") }}</button>
    </template>
    <template v-if="isReady && !$store.state.isQuizFinished && $store.state.isQuizStarted">
      <div class="quiz__container">
        <template v-for="question in $store.state.form.quiz.questions">
          <div v-if="question.id === currentQuestionId" :key="question.id" class="quiz__question">
            <h3 :class="h3MarginClass">{{ $t("orientation.quiz.question") }} {{ question.order }}/{{ $store.state.form.quiz.questions.length }}</h3>
            <p class="quiz__question__text mb-15">
              {{ question.text[$i18n.locale] }}
            </p>
            <p v-if="isCurrentQuestionAnswered" class="mb-25">
              <span v-if="isChoiceValid"><span class="green">{{ $t("orientation.quiz.right") }}</span> {{
                  question.note[$i18n.locale]
                }}</span>
              <span v-else class="red">{{ $t("orientation.quiz.wrong") }}</span>
            </p>
            <Component
              :is="getInputComponent(question.options)"
              v-model="quizState[question.id].choice"
              class="quiz__question__choices"
              :question="question"
              :is-answered="isCurrentQuestionAnswered"
              :answer="quizState[currentQuestionId].answer"
              :choice="quizState[currentQuestionId].choice"
              :is-valid="quizState[currentQuestionId].isValid"
            />
          </div>
        </template>
        <button v-if="!isCurrentQuestionAnswered" class="button button--regular quiz__submit" @click="submitQuestion">
          {{ $t("orientation.quiz.submit") }}
        </button>
        <button
          v-else-if="isCurrentQuestionAnswered && !isLastQuestion"
          class="button button--regular quiz__submit"
          @click="nextQuestion"
        >
          {{ $t("orientation.quiz.next") }}
        </button>
        <button v-else class="button button--regular quiz__submit" @click="submitQuiz">{{
            $t("orientation.quiz.finish")
          }}
        </button>
      </div>
    </template>
    <div v-if="$store.state.isQuizFinished && !$store.state.isQuizPassed" class="quiz__container">
      <div class="quiz__question">
        <h3 class="mb-25">{{ $t("orientation.quiz.finish", {grade}) }}</h3>
        <span v-html="$t('orientation.quiz.failed', {missed: missedQuestions, passing: $store.state.form.quiz.passing_grade})"></span>
        <button class="button button--regular quiz__submit" @click="restartQuiz">
          {{ $t("orientation.quiz.retake", {missed: missedQuestions}) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {inputComponentMap} from './quiz-inputs'

export default {
  name: 'Quiz',
  data() {
    return {
      quizState: {},
      isReady: false,
      currentQuestionIndex: 0, // this.$store.state.form.quiz.questions[0],
      isCurrentQuestionAnswered: false,
      isChoiceValid: false,
      grade: null
    }
  },
  computed: {
    gradingContent() {
      return this.$t('orientation.quiz.gradingContent', {
        passing:this.$store.state.form.quiz.passing_grade,
        goodAnswersRequired: this.goodAnswersRequired,
        totalQuestions: this.$store.state.form.quiz.questions.length
      })
    },
    goodAnswersRequired() {
      return Math.ceil((this.$store.state.form.quiz.questions.length * this.$store.state.form.quiz.passing_grade) / 100)
    },
    currentQuestionId() {
      return this.$store.state.form.quiz.questions[this.currentQuestionIndex].id
    },
    missedQuestions() {
      return Object.values(this.quizState).reduce((badAnswers, question) => {
        if (!question.isValid) badAnswers += 1
        return badAnswers
      }, 0)
    },
    isLastQuestion() {
      let isLast = false

      this.$store.state.form.quiz.questions.forEach((question, index) => {
        if (question.id === this.currentQuestionId && index === this.$store.state.form.quiz.questions.length - 1)
          isLast = true
      })

      return isLast
    },
    h3MarginClass() {
      return this.$device.desktop ? 'mb-50' : 'mb-25'
    }
  },
  created() {
    this.initQuizState()
  },
  methods: {
    restartQuiz() {
      window.location.reload()
    },
    startQuiz() {
      this.$store.commit('setQuizStarted')
    },
    initQuizState() {
      this.$store.state.form.quiz.questions.forEach(question => {
        let validChoices = []
        let validChoice = null
        const options = question.options[this.$i18n.locale]
        if (options.type.toLowerCase() === 'multiple') {
          const {values} = options
          validChoices = values.map(value => {
            return value.valid === true && value.text
          })
        }

        if (options.type.toLowerCase() === 'single') {
          const {values} = options
          validChoice = values.find(value => value.valid === true).text
        }

        if (options.type.toLowerCase() === 't/f') {
          validChoice = options.answer
        }

        this.quizState[question.id] = {
          choice: options.type.toLowerCase() === 'multiple' ? [] : null,
          answer: validChoices.length > 0 ? validChoices : validChoice,
          isValid: false
        }
      })

      this.isReady = true
    },
    getInputComponent(options) {
      return inputComponentMap[options[this.$i18n.locale].type.toLowerCase()]
    },
    submitQuestion() {
      const {choice, answer} = this.quizState[this.currentQuestionId]

      console.log(choice, answer)
      const validators = {
        string: () => choice === answer,
        boolean: () => choice === answer,
        object: () => {
          if (!Array.isArray(choice)) return false
          const cleanAnswers = answer.filter(val => val)
          return cleanAnswers.every(entry => choice.includes(entry)) && choice.length === cleanAnswers.length
        }
      }

      if (validators[typeof choice]()) {
        this.quizState[this.currentQuestionId].isValid = true
        this.isChoiceValid = true
      }
      this.isCurrentQuestionAnswered = true
    },
    nextQuestion() {
      this.currentQuestionIndex += 1
      this.isCurrentQuestionAnswered = false
      this.isChoiceValid = false
    },
    submitQuiz() {
      const result = Object.values(this.quizState).reduce((quizResult, {isValid}) => {
        if (isValid) quizResult += 1
        return quizResult
      }, 0)

      const grade = Math.floor((result / Object.values(this.quizState).length) * 100)
      this.grade = grade

      if (grade > this.$store.state.form.quiz.passing_grade) {
        this.$store.commit('setQuizPassed')
        this.$store.commit('finishQuiz')
        // this.$emit('quiz-passed')
        return
      }

      this.$store.commit('finishQuiz')
    }
  }
}
</script>
