<template>
  <div class="manager-result-table">
    <div v-if="$device.desktop" class="table-header">
      <p>Contractor Name</p>
      <p>Company</p>
      <p>Date of Completion - Online Orientation</p>
      <p>On-Site training Status</p>
    </div>
    <div class="table-container">
      <ul class="letters">
        <li
          v-for="(value, name) in alphabeticallyGroupedContractors"
          :key="`${name}-key`"
          :class="letterClass(name)"
          @click="scrollToAnchor(name)"
        >
          {{ name }}
        </li>
      </ul>

      <ScrollBox ref="scrollingParent" class="table" :target="scrollTarget">
        <template v-for="(value, name) in alphabeticallyGroupedContractors">
          <AlphaGroup :id="name" :key="name" :ref="name" :letter="name" :inductions="value.inductions" />
        </template>
      </ScrollBox>
    </div>
  </div>
</template>

<script>
import inductionsGQL from '@/gql/inductions'
import headers from '@/helpers/headers'
import AlphaGroup from '@/components/_parts/AlphaGroup.vue'
import ScrollBox from '@/components/layout/ScrollBox.vue'

export default {
  name: 'InductionsSearchTable',
  components: {
    AlphaGroup,
    ScrollBox,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    siteId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      inductions: [],
      scrollTarget: null,
      scrollKey: 'A',
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    alphabeticallyGroupedContractors() {
      const data = this.inductions
        .filter(induction => {
          const search = this.search.toLowerCase()
          const fullName = `${induction.user.first_name} ${induction.user.last_name}`
          return fullName.toLowerCase().indexOf(search) > -1
        })
        .reduce((r, e) => {
          // get first letter of name of current element
          // const group = e.last_name[0]
          const [[group]] = e.user.last_name
          // if there is no property in accumulator with this letter create it
          if (!r[group]) r[group] = { group, inductions: [e] }
          // if there is push current element to children array for that letter
          else r[group].inductions.push(e)
          // return accumulator
          return r
        }, {})

      const sorted = Object.keys(data)
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: data[key],
          }),
          {}
        )

      // return data
      return sorted
    },
  },
  watch: {
    siteId() {
      this.getInductions()
    }
  },
  created() {
    this.getInductions()
  },
  methods: {
    scrollToAnchor(key) {
      this.scrollTarget = `#${key}`
      this.scrollKey = key
    },
    letterClass(name) {
      return [
        'mb-10 mt-10 letters__letter',
        {
          selected: name === this.scrollKey,
        },
      ]
    },
    getInductions() {
      this.$apollo
        .query({
          query: inductionsGQL.inductions,
          variables: {
            site_id: this.siteId,
            year: this.currentYear,
          },
          context: {
            headers,
          },
        })
        .then(({ data }) => {
          this.inductions = data.inductions
          this.$store.state.currentInductions = data.inductions
        })
        .catch(console.error)
    },
  },
}
</script>
