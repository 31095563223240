<template>
  <div>
    <template v-if="$device.phone">
      <img class="contractor-search__logo" src="/images/oldcastle_logomark.png" alt="" />
      <button class="contractor-search__logout" @click="$router.replace({ name: 'logout' })">
        <i class="material-icons">power_settings_new</i>
      </button>
    </template>
    <label class="contractor-search__label" for="contractor-search">
      <div class="contractor-search__title">
        <span>List of Contractors</span>
        <strong v-if="$device.desktop"> | {{ currentSite.name }}</strong>
        <p v-if="$device.phone">{{ currentSite.name }}</p>
      </div>
      <label class="contractor-search__input-container">
        <input
          id="contractor-search"
          class="contractor-search__input"
          type="text"
          placeholder="Type the name of a contractor..."
          @input="$emit('input', $event.target.value)"
        />
        <i class="material-icons">search</i>
      </label>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ContractorSearchbar',
  props: {
    siteId: {
      type: Number
    }
  },
  computed: {
    currentSite() {
      return this.$store.state.user.managingSites.find(({id}) => id === this.siteId)
    }
  }
}
</script>
