<template>
  <transition name="slide-fade" appear>
    <ScrollBox :class="menuClassName">
      <div class="slide-sidemenu__content">
        <button @click="$globalEvents.emit('close-menu', '')">
          <i class="material-icons close-button">close</i>
        </button>

        <h2 class="condensed slide-sidemenu__content__title">{{ title }} Profile</h2>

        <div class="slide-sidemenu__content__action-buttons">
          <button v-if="!isEdit" class="slide-sidemenu__content__action-button" @click="edit">
            <i class="material-icons">edit</i>
          </button>
          <button v-if="!isEdit && canDelete" class="slide-sidemenu__content__action-button" @click="deleteEntity">
            <i class="material-icons">delete</i>
          </button>
        </div>

        <component
            :is="formComponent"
            v-if="!reload"
            v-bind="formComponentProps"
            class="slide-sidemenu__content__profile-content"
            @save="save"
            @reload="reloadComponent"
            @can-delete="showDelete"
        />
      </div>
    </ScrollBox>
  </transition>
</template>

<script>
import {entityTypesComponentMap} from '@/store/constants'
import ScrollBox from '@/components/layout/ScrollBox.vue'

export default {
  name: 'SlideSideMenu',
  components: {
    ScrollBox,
  },
  data() {
    return {
      isOpen: false,
      reload: false,
      type: '',
      isEdit: false,
      canDelete: false,
      entityId: null,
      titles: {
        siteManager: 'Site Manager',
        siteManagers: 'Site Manager',
        site: 'Site',
        sites: 'Site',
        company: 'Company',
        companies: 'Company',
        contractor: 'Contractor',
        contractors: 'Contractor',
      },
    }
  },
  computed: {
    menuClassName() {
      return [
        'slide-sidemenu',
        {
          opened: this.isOpen,
        },
      ]
    },
    title() {
      return this.titles[this.type]
    },
    formComponent() {
      if (this.type === '') return
      return entityTypesComponentMap[this.type]
    },
    formComponentProps() {
      return {
        isEdit: this.isEdit,
        id: this.entityId,
      }
    },
  },
  beforeMount() {
    this.$globalEvents.on('open-menu', (type, id) => {
      this.type = type
      this.entityId = id
      this.$store.commit('openAdminMenu')
      this.isOpen = true
    })

    this.$globalEvents.on('close-menu', () => {
      this.$store.commit('closeAdminMenu')
      this.isOpen = false
      this.isEdit = false
      this.canDelete = false
      this.entityId = null
      this.type = ''
    })
    this.$globalEvents.on('reload-menu', () => {
      console.log("Reload side")
      this.reloadComponent()
    });
  },
  methods: {
    reloadComponent() {
      this.reload = true
      this.$nextTick(() => {this.reload = false})
    },
    edit() {
      this.isEdit = true
    },
    save() {
      this.isEdit = false
    },
    deleteEntity() {
      // todo Confirm message modal
      this.$globalEvents.emit('side-delete-entity', this.type)
      this.$globalEvents.emit('reload-search-results', this.type)
      this.$globalEvents.emit('close-menu', '')
    },
    showDelete() {
      this.canDelete = true
    },
  },
}
</script>
