import gql from 'graphql-tag'

// QUERIES

const getContractorProfile = gql`
  query GetContractorProfile($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      email
      date_of_birth
      companies {
        id
        name
      }
      inductions {
        id
        on_site_training_done
        completed_at
        site {
          id
          name
        }
      }
    }
  }
`

const searchContractors = gql`
  query SearchContractors($search: String, $siteId: ID!) {
    contractors(search: $search, site_id: $siteId) {
      data {
        id
        first_name
        last_name
        date_of_birth
        companies {
          id
          name
        }
        inductions {
          id
          site_id
          on_site_training_done
          completed_at
        }
      }
    }
  }
`

// MUTATIONS

const updateContractor = gql`
  mutation UpdateContractor($id: ID!, $email: String!, $dateOfBirth: Date!, $firstName: String!, $lastName: String!) {
    updateUser(
      id: $id
      input: { email: $email, date_of_birth: $dateOfBirth, first_name: $firstName, last_name: $lastName }
    ) {
      id
      email
      date_of_birth
      first_name
      last_name
    }
  }
`

const resendCertificate = gql`
  mutation ResendInductionCertificate($id:ID!) {
    resendInductionCertificate(id: $id) {
      message
    }
  }
`

const attachSite = gql`
  mutation AttachSite($id: ID!, $site_id: ID!) {
    attachSite(
      id: $id
      site_id: $site_id
    ) { id }
  }
`

const detachSite = gql`
  mutation DetachSite($id: ID!, $site_id: ID!) {
    detachSite(
      id: $id
      site_id: $site_id
    ) { id }
  }
`

const contractorGQL = {
  getContractorProfile,
  updateContractor,
  searchContractors,
  resendCertificate,
  attachSite,
  detachSite
}

export default contractorGQL
