<template>
  <div class="admin-search-bar">
    <span ref="topRefNode" />
    <h2 class="slim-title">Search</h2>
    <div class="admin-search-bar__container">
      <label class="admin-search-bar__container__select">
        <i class="material-icons">keyboard_arrow_down</i>
        <select id="search-type" v-model="selectType" name="search-type" @change="resetValues">
          <option
            v-for="option in options"
            :key="option.value"
            :value="option.value"
            :selected="isSelected(option.value)"
          >
            {{ option.label }}
          </option>
        </select>
      </label>
      <div class="admin-search-bar__container__input">
        <input v-model="searchValue" type="text" placeholder="Search something" />
      </div>
    </div>
    <div v-if="loading" class="admin-search-bar__loading">Loading...</div>
    <AdminResultTable
        v-if="!reload"
        :search-type="selectType"
        :search-string="searchValue"
        :enabled-button="enabledButton"
        :page="currentPage"
        @reset-search="resetValues"
        @enable-button="enabledButton = true"
        @disable-button="enabledButton = false"
        @increase-page="currentPage++"
        @data-updated="onUpdate" />
  </div>
</template>

<script>
import AdminResultTable from '@/components/_parts/AdminResultTable.vue'

export default {
  name: 'AdminSearchBar',
  components: {
    AdminResultTable,
  },
  data() {
    return {
      enabledButton: true,
      currentPage: 1,
      searchValue: '',
      selectType: 'sites',
      loading: false,
      ready: false,
      options: [
        { value: 'sites', label: 'Sites' },
        { value: 'contractors', label: 'Contractors' },
        { value: 'companies', label: 'Companies' },
        { value: 'siteManagers', label: 'Managers' },
      ],
      reload: false
    }
  },
  watch: {
    searchValue(to) {
      if (to) this.loading = true
      else this.loading = false
    },
  },
  beforeMount() {
    this.$globalEvents.on('reload-menu', () => {
      this.reload = true
      this.$nextTick(() => {this.reload = false})
    })
  },
  mounted() {
    setTimeout(() => {
      this.ready = true
    }, 1000)
  },
  methods: {
    resetValues() {
      this.enabledButton = true;
      this.currentPage = 1;
    },
    isSelected(value) {
      return value === this.selectType
    },
    onUpdate() {
      this.loading = false;
    },
  },
}
</script>
