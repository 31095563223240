const getYear = (date = null) => {
  let year = null;
  if (!date) year = new Date();
  else year = new Date(date)
  return year.getFullYear()
}

const getYearMonthDay = date => {
  const dateObject = new Date(date)
  console.log(dateObject.getMonth());
  const year = dateObject.getFullYear()
  const month = `${dateObject.getMonth() + 1}`.padStart(2, '0')
  const day = `${dateObject.getDay() + 1}`.padStart(2, '0')
  return `${year}-${month}-${day}`
}

const startYear = 2022;

const years = {
  getYear,
  getYearMonthDay,
  startYear
}

export default years
