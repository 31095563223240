import Vue from 'vue'
import Vuex from 'vuex'

// ACTIONS
import getForm from '@/store/actions/getForm'
import editForm from '@/store/actions/editForm'
import login from '@/store/actions/login'
import logout from '@/store/actions/logout'
import verifyCurrentSession from '@/store/actions/verifyCurrentSession'
import verifyEmail from '@/store/actions/verifyEmail'
import initOrientation from '@/store/actions/initOrientation'
import startOrientation from '@/store/actions/startOrientation'
import saveNewSection from '@/store/actions/saveNewSection'
import deleteSection from '@/store/actions/deleteSection'
import updateQuiz from '@/store/actions/updateQuiz'
import resendCertificate from '@/store/actions/resendCertificate'
import attachSite from '@/store/actions/attachSite'
import detachSite from '@/store/actions/detachSite'

// MUTATIONS
import resetUser from '@/store/mutations/resetUser'
import setUser from '@/store/mutations/setUser'
import setForm from '@/store/mutations/setForm'
import setOrientationInitialState from '@/store/mutations/setOrientationInitialState'
import setOrientationStarted from '@/store/mutations/setOrientationStarted'
import setOrientationFinished from '@/store/mutations/setOrientationFinished'
import changeSection from '@/store/mutations/changeSection'
import markSectionAsVisited from '@/store/mutations/markSectionAsVisited'
import finishQuiz from '@/store/mutations/finishQuiz'
import setQuizStarted from '@/store/mutations/setQuizStarted'
import setQuizPassed from '@/store/mutations/setQuizPassed'
import createSection from '@/store/mutations/createSection'
import updateNewSectionState from '@/store/mutations/updateNewSectionState'
import deleteStateSection from '@/store/mutations/deleteStateSection'
import reorderSections from '@/store/mutations/reorderSections'
import adminChangeSection from '@/store/mutations/adminChangeSection'
import closeManagerMobileMenu from '@/store/mutations/closeManagerMobileMenu'
import openManagerMobileMenu from '@/store/mutations/openManagerMobileMenu'
import openAdminMenu from '@/store/mutations/openAdminMenu'
import closeAdminMenu from '@/store/mutations/closeAdminMenu'
import publishForm from "@/store/actions/publishForm";

// GETTERS
import role from '@/store/getters/role'
import siteId from '@/store/getters/siteId'
import loggedInUser from '@/store/getters/loggedInUser'
import inductionId from '@/store/getters/inductionId'
import activeOrientationSection from '@/store/getters/activeOrientationSection'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    isAuthenticated: false,
    activeOrientationSectionIndex: 0,
    activeQuizQuestionIndex: 0,
    form: {
      sections: [],
    },
    isOrientationStarted: false,
    isOrientationFinished: false,
    isQuizStarted: false,
    isQuizFinished: false,
    isQuizPassed: false,
    managerMobileMenuOpen: false,
    managerSelectedInduction: {},
    admin_current_section: 'quick-actions',
    isAdminMenuOpen: false,
  },
  mutations: {
    setUser,
    resetUser,
    setForm,
    setOrientationInitialState,
    setOrientationStarted,
    changeSection,
    markSectionAsVisited,
    finishQuiz,
    setQuizPassed,
    setOrientationFinished,
    setQuizStarted,
    createSection,
    updateNewSectionState,
    deleteStateSection,
    reorderSections,
    adminChangeSection,
    closeManagerMobileMenu,
    openManagerMobileMenu,
    openAdminMenu,
    closeAdminMenu,
    resetForm: (state) => {
      state.form = {
        sections: [],
      }
    }
  },
  actions: {
    login,
    logout,
    verifyCurrentSession,
    getForm,
    initOrientation,
    startOrientation,
    saveNewSection,
    deleteSection,
    updateQuiz,
    verifyEmail,
    editForm,
    publishForm,
    resendCertificate,
    attachSite,
    detachSite
  },
  getters: {
    role,
    loggedInUser,
    siteId,
    inductionId,
    activeOrientationSection,
  },
})
